<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <b-img
        :src="appLogoImage"
        alt="logo"
        center
        width="200px"
        class="mb-5"
      />
      <div class="w-100 text-center">
        <div v-if="!hasError">
          <h2 class="mb-1">
            Waiting... We will redirect you to the autosupport information page
          </h2>
        </div>

        <div v-else>
          <div v-if="assetNotFound">
            <h2 class="mb-1">
              Could not find asset
            </h2>
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{path:'/'}"
            >
              Back to home
            </b-button>
          </div>
          <div v-else>
            <h2
              v-if="assetHasNoAsupData"
              class="mb-1"
            >
              Could not find autosupport data
            </h2>
            <h2
              v-if="assetHasNoProductFamilyInfo"
              class="mb-1"
            >
              Could not determine product family
            </h2>
            <h2
              v-if="productFamilyHasNoAsupImplemented"
              class="mb-1"
            >
              Asset has autosupport data, but currently we do not have any view implemented for {{ productFamily }}
            </h2>
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{name:'asset-detail-page', params: { id: assetId}}"
            >
              Back to asset
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

import AssetService from '@/service/asset.service'

export default {
  components: {
    BImg,
    BButton,
  },
  data() {
    return {
      assetNotFound: false,
      assetHasNoAsupData: false,
      assetHasNoProductFamilyInfo: false,
      productFamilyHasNoAsupImplemented: false,
      productFamily: null,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
  computed: {
    assetId() {
      return this.$route.params.id
    },
    shouldAutoRedirectToAssetOnFailure() {
      return !!this.$route.query.ar
    },
    hasError() {
      return this.assetNotFound
            || this.assetHasNoAsupData
            || this.assetHasNoProductFamilyInfo
            || this.productFamilyHasNoAsupImplemented
    },
  },
  watch: {
    hasError(val) {
      if (val) {
        // if any error occured (except assetNotFound) and query param is set, we auto-redirect to asset page
        if (this.assetNotFound === false && this.shouldAutoRedirectToAssetOnFailure === true) {
          this.$router.replace({ name: 'asset-detail-page', params: { id: this.assetId } })
        }
      }
    },
  },
  mounted() {
    AssetService.getAsync(this.assetId, { disableTenantFilter: true })
      .then(result => {
        if (result.asupSystemId && result.productFamily) {
          this.productFamily = result.productFamily
          this.redirectToAsup(result.asupSystemId, result.asupSystemType)
        } else if (!result.asupSystemId) {
          this.assetHasNoAsupData = true
        } else if (!result.productFamily) {
          this.assetHasNoProductFamilyInfo = true
        }
      })
      .catch(() => {
        this.assetNotFound = true
      })
  },
  methods: {
    redirectToAsup(asupSystemId, asupSystemType) {
      const routeParams = { id: asupSystemId }
      switch (asupSystemType) {
        case 1000:
          this.$router.replace({ name: 'datadomain-detail.general', params: routeParams })
          break
        case 1008:
          this.$router.replace({ name: 'networker-detail.general', params: routeParams })
          break
        case 1010:
          this.$router.replace({ name: 'avamar-detail.general', params: routeParams })
          break
        case 1012:
          this.$router.replace({ name: 'ppdm-detail.general', params: routeParams })
          break
        case 1006:
          this.$router.replace({ name: 'veeam-detail.general', params: routeParams })
          break
        case 21000:
          this.$router.replace({ name: 'datadomain-apex-detail.general', params: routeParams })
          break
        case 21001:
          this.$router.replace({ name: 'generic-detail.general', params: routeParams })
          break
        default:
          this.productFamilyHasNoAsupImplemented = true
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
